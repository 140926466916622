import { Card, Grid, useMediaQuery, useTheme } from "@mui/material"
import React from "react"
import Font18 from "components/Fonts/Font18"
import Font12 from "components/Fonts/Font12"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { smallParagraphOptions } from "constants/richTextOptions"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { StyledAvatar } from "./style"

const WorkerCard = ({
  name,
  surname,
  avatar,
  position,
  description,
  mail,
  phoneNumber,
}) => {
  const theme = useTheme()
  const isLg = useMediaQuery(theme.breakpoints.up("lg"))
  const avatarImg = getImage(avatar)

  return (
    <Card style={{ maxWidth: isLg ? "none" : 420 }}>
      <Grid container direction="row">
        <Grid item xs={12} lg={5} mb={{ xs: 2, lg: 0 }}>
          <StyledAvatar alt={`${name} ${surname}`} variant="big">
            <GatsbyImage image={avatarImg} alt={`${name} ${surname}`} />
          </StyledAvatar>
        </Grid>
        <Grid item xs={12} lg={7}>
          <Font18>
            {name} {surname}
          </Font18>
          <Font12 sx={{ opacity: 0.6 }} mb={3}>
            {position}
          </Font12>
          <Grid mb={3}>
            {renderRichText(description, smallParagraphOptions)}
          </Grid>
          {mail && (
            <Font12 sx={{ opacity: 0.6 }} mb={0}>
              {mail}
            </Font12>
          )}
          {phoneNumber && <Font12 sx={{ opacity: 0.6 }}>{phoneNumber}</Font12>}
        </Grid>
      </Grid>
    </Card>
  )
}

export default WorkerCard
