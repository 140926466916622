import React from "react"
import Layout from "hoc/Layout"
import Header from "containers/Header/Header"
import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import Workers from "containers/Company/Workers"
import { useMediaQuery, useTheme } from "@mui/material"

const OurTeam = () => {
  const breadcrumbsData = [
    {
      title: "Strona Główna",
      link: "/",
    },
    {
      title: "O nas",
      link: "/o-nas/firma",
    },
    "Nasz zespół",
  ]
  const { ourTeamMain } = useStaticQuery(
    graphql`
      query {
        ourTeamMain: file(name: { eq: "our-team-main" }) {
          id
          childImageSharp {
            gatsbyImageData(
              backgroundColor: "transparent"
              quality: 100
              placeholder: NONE
            )
          }
        }
      }
    `
  )

  const mainImage = getImage(ourTeamMain)
  const theme = useTheme()
  const isSm = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <Layout breadcrumbsData={breadcrumbsData} seoTitle="Nasz zespół">
      <Header
        title={"Nasz zespół"}
        titleDescription={
          "Eksperci w dziedzinie rekrutacji i obsługi klienta. Współpracując z Medirą mogą mieć Państwo pewność, że będziecie obsługiwani przez najlepszych branżowych specjalistów.  "
        }
        mainImage={mainImage}
        mainImageAlt={"Nasz zespół"}
        sx={{ margin: isSm ? "0px 0 35px" : "40px 0 35px" }}
      />
      <Workers />
    </Layout>
  )
}

export default OurTeam
