import React from "react"
import Hr from "components/Hr/Hr"
import Font42 from "components/Fonts/Font42"
import { Grid } from "@mui/material"
import WorkerCard from "./WorkerCard"

const Department = ({ name, pracownik, ...rest }) => {
  return (
    <Grid {...rest}>
      <Hr mb={4} mt={6} />
      <Font42>{name}</Font42>
      <Grid
        container
        spacing={4}
        mt={4}
        justifyContent={{ xs: "center", md: "flex-start" }}
      >
        {pracownik
          .sort((a, b) => a?.order - b?.order)
          .map(worker => {
            return (
              <Grid
                item
                xs={12}
                sm={9}
                md={6}
                container={{ xs: true, md: false }}
                justifyContent={{ xs: "center", md: "flex-start" }}
              >
                <WorkerCard {...worker} />
              </Grid>
            )
          })}
      </Grid>
    </Grid>
  )
}

export default Department
